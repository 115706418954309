
import { View } from 'client-website-ts-library/plugins';
import { ProfileFilter } from 'client-website-ts-library/filters';
import { API, Config, Logger, LogLevel } from 'client-website-ts-library/services';
import { Franchise, Office, Profile, WebsiteLevel } from 'client-website-ts-library/types';
import { Component, Mixins } from 'vue-property-decorator';

import ProfileCards from '../components/ProfileCards.vue';
import Loader from '../components/UI/Loader.vue';

@Component({
  components: {
    ProfileCards,
    Loader,
  },
})
export default class About extends Mixins(View) {
  private offices: Office[] = [];

  private office: Office | null = null;

  private franchise: Franchise | null = null;

  private profiles: Profile[] = [];

  mounted() {
    const filter = new ProfileFilter();

    this.loadOffice();

    API.Profiles.Search(filter, true).then((profiles) => {
      this.profiles = profiles;
    });
  }

  loadOffice(): void {
    switch (Config.Website.Settings!.WebsiteLevel) {
      case WebsiteLevel.Profile:

        API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then((profile) => {
          this.office = profile.Office;
        });

        break;
      case WebsiteLevel.Office:

        API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
          this.office = office;
        });

        break;
      case WebsiteLevel.Franchise:
        API.Franchises.GetOffices(Config.Website.Settings!.WebsiteId).then((offices) => {
          this.offices = offices;
        });

        API.Franchises.Get(Config.Website.Settings!.WebsiteId).then((franchise) => {
          this.franchise = franchise;
        });

        break;
      default:
        Logger.Log(LogLevel.Error, 'Failed to load office for website. Website level is invalid.');
        break;
    }
  }

  get sales(): Profile[] {
    return this.profiles.filter((p) => p.Office.OfficeName === 'Cairns Sales');
  }

  get rentals(): Profile[] {
    return this.profiles.filter((p) => p.Office.OfficeName === 'Cairns Rentals');
  }
}
